import m from 'mithril'
import LanguagePicker from './_language-picker'
import NavBarDropdown from '../common/dropdown/navbar-dropdown'
import UnitSystemPicker from './_unit-system-picker'
import { hasAction, isAuth } from '../../models/auth'
import state from '../../models/state'
import t from '../../lib/translate'
import logo from '../../img/selection-tool-logo.svg'
import configuratorHeader from './_configurator-header'

const Header = () => {
  let isMenuOpen = false

  const clickHandler = e => {
    const parent = document.getElementById('header-menu')
    if ((parent === e.target || parent.contains(e.target)) &&
      e.target.nodeName !== 'A' && e.target.parentElement.nodeName !== 'A') return
    isMenuOpen = !isMenuOpen
    m.redraw()
    if (!isMenuOpen) document.removeEventListener('click', clickHandler)
  }

  const getWizardSections = () => [
    {
      slug: 'configurator-wizard',
      icon: 'faHandPointer',
      label: t('configurator.select_product_type')
    },
    {
      slug: 'configurator-search',
      icon: 'faSearch',
      label: t('configurator.search_by_name')
    }
  ]

  const getAdvancedSections = () => [
    {
      slug: 'custom-configurator-wizard',
      acl: 'configurator.custom_products',
      icon: 'faWrench',
      label: t('configurator.custom_product')
    },
    {
      slug: 'custom-coils-configurator-wizard',
      acl: 'configurator.custom_coil',
      icon: 'faCog',
      label: t('configurator.custom_coil')
    },
    {
      slug: 'coil-costing-wizard',
      acl: 'configurator.costing',
      icon: 'faFileInvoiceDollar',
      label: t('configurator.coil_costing')
    },
    {
      slug: 'unit-costing-wizard',
      acl: 'configurator.costing',
      icon: 'faFileInvoiceDollar',
      label: t('configurator.unit_costing')
    }
  ].filter(section => hasAction(section.acl))

  function view ({ attrs }) {
    const S = state()
    const wizardSections = getWizardSections()
    const advancedSections = getAdvancedSections()
    const hasAdvanced = advancedSections.length > 0
    const isWizard = wizardSections.find(section => section.slug === attrs.app.section)
    const isAdvanced = hasAdvanced && advancedSections.find(section => section.slug === attrs.app.section)
    const showMenu = isAuth() && !attrs.isPublic && !attrs.isMaintenance
    return m('.header', {
      className: !attrs.isPublic && !attrs.isMaintenance ? 'has-background-white' : ''
    }, [
      m('.container', [
        // NAVBAR MENU
        m('nav.navbar.is-transparent', [
          m('.navbar-brand', [
            !attrs.isPublic && !attrs.isMaintenance && m(m.route.Link, {
              className: 'navbar-logo',
              href: '/'
            }, [
              m('img.navbar-logo-image', {
                alt: 'Selection Tool',
                src: logo,
                height: 52
              })
            ]),
            m('.navbar-burger.burger', {
              'aria-label': 'menu',
              'aria-expanded': isMenuOpen,
              className: isMenuOpen ? 'is-active' : '',
              'data-target': 'header-menu',
              onclick (e) {
                e.preventDefault()
                document.addEventListener('click', clickHandler)
              }
            }, [
              m('span'),
              m('span'),
              m('span')
            ])
          ]),
          m('.navbar-menu', {
            id: 'header-menu',
            className: isMenuOpen ? 'is-active' : ''
          }, [
            m('.navbar-end', [
              showMenu && [
                m(NavBarDropdown, {
                  text: S.user ? `${S.user.firstName} ${S.user.lastName}` : ''
                }, [
                  m(m.route.Link, {
                    className: attrs.app.section === 'profile' ? 'navbar-item is-active' : 'navbar-item',
                    href: '/profile'
                  }, t('nav.profile')),
                  m(m.route.Link, {
                    className: 'navbar-item',
                    href: '/login'
                  }, t('nav.logout'))
                ]),
                (hasAction('acl.drop_sessions') || hasAction('users.list')) && m(NavBarDropdown, {
                  text: t('nav.admin')
                }, [
                  hasAction('acl.drop_sessions') && m(m.route.Link, {
                    className: attrs.app.section === 'admin' ? 'navbar-item is-active' : 'navbar-item',
                    href: '/admin'
                  }, t('nav.general')),
                  hasAction('users.list') && m(m.route.Link, {
                    className: attrs.app.section === 'users' || attrs.app.section === 'new-user' ? 'navbar-item is-active' : 'navbar-item',
                    href: '/users'
                  }, t('nav.users')),
                  hasAction('acl.config') && m(m.route.Link, {
                    className: attrs.app.section === 'acl-roles' || attrs.app.section === 'acl-groups' ? 'navbar-item is-active' : 'navbar-item',
                    href: '/acl-groups'
                  }, t('nav.acl'))
                ]),
                m(UnitSystemPicker, {
                  app: attrs.app
                })
              ],
              m(LanguagePicker)
            ])
          ])
        ]),
        // NAVBAR TABS
        showMenu && m('.tabs.is-right.header-tabs', [
          m('ul', [
            m('li', {
              className: isWizard ? 'is-active' : ''
            }, m(m.route.Link, {
              className: 'is-uppercase',
              href: '/configurator-wizard/1'
            }, t('nav.configurator'))),
            hasAdvanced && m('li', {
              className: isAdvanced ? 'is-active' : ''
            }, m(m.route.Link, {
              className: 'is-uppercase',
              href: '/custom-configurator-wizard/1'
            }, t('nav.advanced'))),
            m('li', {
              className: attrs.app.section === 'offers' || attrs.app.section === 'positions' ? 'is-active' : ''
            }, m(m.route.Link, {
              className: 'is-uppercase',
              href: '/offers'
            }, t('nav.offers'))),
            m('li', {
              className: attrs.app.section === 'customers' || attrs.app.section === 'new-customer' ? 'is-active' : ''
            }, m(m.route.Link, {
              className: 'is-uppercase',
              href: '/customers'
            }, t('nav.customers')))
          ])
        ]),
        showMenu && (isWizard || isAdvanced) && configuratorHeader({
          sections: isWizard ? wizardSections : advancedSections,
          section: attrs.app.section
        })
      ])
    ])
  }

  return { view }
}

export default Header
