import m from 'mithril'
import Icon from '../common/icon/icon'
import cS from '../../models/configurator'

const tabs = (cs, sections, slug) => m('.tabs.configurator-tabs.is-fullwidth.is-toggle', [
  m('ul', sections.map(section => m('li', {
    className: section.slug === slug ? 'is-active' : ''
  }, m(m.route.Link, {
    href: `/${section.slug}/1`,
    title: section.label
  }, [
    m(Icon, { name: section.icon }),
    m('span.is-hidden-mobile.ml2', section.label)
  ]))))
])

const configuratorHeader = ({ sections, section }) => {
  const cs = cS()
  return tabs(cs, sections, section)
}

export default configuratorHeader
