import m from 'mithril'
import Modal from '../common/modal/modal'
import NavBarDropdown from '../common/dropdown/navbar-dropdown'
import { changeConfiguratorUnitSystem, changeCustomConfiguratorUnitSystem } from '../../api'
import cS from '../../models/configurator'
import ccS from '../../models/custom-configurator'
import { formatConfiguratorRequest } from '../../models/configurator-request'
import { formatCustomConfiguratorRequest } from '../../models/custom-configurator-request'
import state from '../../models/state'
import { formatUnitSystemSwitch } from '../../models/unit-system'
import { getOptions } from '../../models/configurator-options'
import t from '../../lib/translate'

const UnitSystemPicker = () => {
  let showConfirmModal = false

  const changeUnitSystem = ({ isCustom = false }) => {
    const changeUnitSystem = isCustom
      ? changeCustomConfiguratorUnitSystem
      : changeConfiguratorUnitSystem
    const formatRequest = isCustom
      ? formatCustomConfiguratorRequest
      : formatConfiguratorRequest
    const C = isCustom ? ccS : cS
    const isDetailRequest = !!(C().productConfig) || isCustom
    return changeUnitSystem(formatRequest(C()), isDetailRequest)
      .then(response => {
        state.update({ unitSystem: response.unitSystem })
        delete response.ventilationTech // Fix for lowercase property names in response
        C.update(formatUnitSystemSwitch(response, isCustom))
        showConfirmModal = false
      })
  }

  function view ({ attrs }) {
    const isCustom = attrs.app.section === 'custom-configurator-wizard'
    const isConfigurator = attrs.app.section === 'configurator-wizard' || attrs.app.section === 'custom-configurator-wizard'
    const configuratorState = isCustom ? ccS() : cS()
    const currentUnit = state().unitSystem
    const canSwitch = configuratorState.ui.allowSubmit
    const productTypes = getOptions('productType', { isCustom: true }).map(option => option.label)
    return [
      m(NavBarDropdown, {
        text: [
          m('span.is-hidden-tablet', t('common.unit_of_measurement')),
          m('span.is-hidden-mobile', currentUnit)
        ]
      },
      [
        { value: 'SI', label: t('configurator.unit_system_si') },
        { value: 'SIB', label: t('configurator.unit_system_sib') }
      ]
        .filter(opt => attrs.app.section !== 'coil-costing-wizard' || opt.value !== 'SIB')
        .map(opt => opt.value === currentUnit
          ? m('span.navbar-item', [
            m('strong.has-text-primary', `${opt.label} (${opt.value})`)
          ])
          : m('a.navbar-item', {
            onclick (e) {
              e.preventDefault()
              if (isConfigurator) {
                showConfirmModal = true
              } else {
                state.update({ unitSystem: opt.value })
              }
            }
          }, `${opt.label} (${opt.value})`))
      ),
      showConfirmModal && m(Modal, {
        title: t('configurator.unit_system_change_modal_title'),
        close: () => {
          showConfirmModal = false
        },
        buttons: canSwitch
          ? null
          : m('button.button', {
            onclick () {
              showConfirmModal = false
            }
          }, t('common.close')),
        submit: () => changeUnitSystem({ isCustom })
      }, [
        m('p.message.is-warning', [
          m('.message-body', canSwitch
            ? t('configurator.unit_system_change_modal_body_warning')
            : !configuratorState.productType
              ? t('configurator.unit_system_change_modal_body_choose_product_type', { productTypes: productTypes.join(' / ') })
              : t('configurator.unit_system_change_modal_body_not_possible')
          )
        ])
      ])
    ]
  }

  return { view }
}

export default UnitSystemPicker
